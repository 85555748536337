<template>
  <h1 class="page-title heading-color tw-mt-6 tw-mb-7">
    <slot>
      Settings
    </slot>
  </h1>
</template>

<script>
export default {
  name: 'SettingsPageHeader',
};
</script>
